<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="indigo lighten-5" flat>
        <BackButton />
        <v-toolbar-title>{{ partner.name }}</v-toolbar-title>
        <v-chip v-if="getStatus" class="ml-3" :text-color="getStatus.color" small>
          <v-avatar left>
            <v-icon :color="getStatus.color" size="20">{{ getStatus.icon }}</v-icon>
          </v-avatar>
          <span class="text-caption">{{ getStatus.title }}</span>
        </v-chip>
        <v-spacer></v-spacer>
        <div class="font-italic font-weight-medium">Картка партнера</div>

        <template v-slot:extension>
          <v-tabs v-model="currentTab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab v-for="(item, index) in tabs" :key="index" @click="editCard = false">
              {{ item.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="currentTab">
        <v-tab-item v-for="(item, index) in tabs" :key="index">
          <v-card flat color="blue-grey lighten-5">
            <component
              v-if="isPartnerExist && isComponentRerendered && isLoaded"
              :is="item.component"
              :currentItem="partner"
              :partnership="partnership"
              :isDataSaved="isDataSaved"
              :currentTabName="currentTabName"
              :editCard="editCard"
              @saveData="saveData"
              @changes="editCard = true"
            ></component>
            <v-container v-show="showEditButtons" fluid>
              <div class="d-flex justify-end">
                <v-btn v-if="!editCard && !isContactsTabActive" @click="editCard = true" class="mr-2" small>Редагувати</v-btn>
                <v-btn v-if="editCard" @click="editCard = false" class="mr-2" small>
                  {{ isContactsTabActive ? 'Скасувати' : 'Повернутись' }}
                </v-btn>
                <v-btn v-show="editCard" @click="showModal = true" color="primary" small>Зберегти</v-btn>
              </div>
            </v-container>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <ConfirmActionModal @closeModal="showModal = false" :showModal="showModal">
      <template v-slot:title>Зберегти зміни?</template>
      <template v-slot:text>Дані будуть оновлені</template>
      <template v-slot:buttons>
        <v-btn @click="isDataSaved = true" color="primary" text>Так</v-btn>
        <v-btn @click="showModal = false" text>Нi</v-btn>
      </template>
    </ConfirmActionModal>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import ConvertStatusesTypes from '@/mixins/convertStatusesTypes'
import ForceRerender from '@/mixins/forceRerender'
import BackButton from '@/components/common/BackButton.vue'

export default {
  name: 'PartnerCard',

  mixins: [ConvertStatusesTypes, ForceRerender],

  components: {
    BackButton,
    ConfirmActionModal: () => import('@/components/dialogs/ConfirmActionModal'),
    GeneralInfo: () => import('@/components/administration/partner/GeneralInfo'),
    CardContacts: () => import('@/components/partials/contacts/CardContacts.vue'),
    Points: () => import('@/components/administration/partner/Points'),
  },

  data() {
    return {
      showModal: false,
      currentTab: 0,
      isDataSaved: false,
      isLoaded: false,
      editCard: false,
      updatedPartnership: '',
      tabs: [
        {
          name: 'info',
          title: 'інфо',
          component: 'GeneralInfo',
        },
        {
          name: 'servicePoints',
          title: 'Торгівельні точки',
          component: 'Points',
        },
        {
          name: 'contacts',
          title: 'Контакти',
          component: 'CardContacts',
        },
      ],
    }
  },

  computed: {
    ...mapState('partners', ['partner', 'partnership']),
    ...mapState('dictionaries', ['technicalContactTypesDictionary', 'personalContactTypesDictionary']),
    currentCompanyId() {
      return this.$route.params.id
    },
    currentPartnerId() {
      return this.$route.params.partnerId
    },
    isPartnerExist() {
      return Object.entries(this.partner).length
    },
    showEditButtons() {
      return this.currentTabName !== 'servicePoints'
    },
    isContactsTabActive() {
      return this.currentTabName === 'contacts'
    },
    currentTabName() {
      return this.tabs[this.currentTab].name
    },
  },

  created() {
    this.initialize()
  },

  beforeDestroy() {
    this.SET_UPDATED_PARTNER({})
    this.SET_SELECTED_PARTNERSHIP({})
    this.SET_ADDRESS({})
  },

  methods: {
    ...mapActions('partners', [
      'loadSelectedPartner',
      'loadSelectedPartnership',
      'updateSelectedPartner',
      'updateSelectedPartnership',
    ]),
    ...mapActions('addresses', ['loadAddress']),
    ...mapActions('logs', ['displayWarningAlert']),
    ...mapMutations('partners', ['SET_UPDATED_PARTNER', 'SET_SELECTED_PARTNERSHIP']),
    ...mapMutations('addresses', ['SET_ADDRESS']),

    async initialize() {
      if (this.currentCompanyId !== 'undefined') {
        const payload = {
          companyId: this.currentCompanyId,
          partnerId: this.currentPartnerId,
        }
        await Promise.all([this.loadSelectedPartner(payload), this.loadSelectedPartnership(payload)])
        if (this.partner.address) {
          const addressPayload = {
            companyId: this.currentCompanyId,
            partnerId: this.currentPartnerId,
            addressId: this.partner.address.id,
            type: 'partner',
          }
          await this.loadAddress(addressPayload)
        }
        this.isLoaded = true
      } else {
        await this.displayWarningAlert({ message: 'Оберіть компанію' })
        await this.$router.replace('/spa')
      }
    },
    async saveData(payload) {
      if (this.currentTab === 2) {
        delete payload.id
        delete payload.address
        this.updatedPartnership = {
          partnership_start_date: this.partnership.partnership_start_date,
        }
      } else {
        delete payload.updatedPartner.id
        delete payload.updatedPartner.address
      }
      const payloadPartner = {
        companyId: this.currentCompanyId,
        partnerId: this.currentPartnerId,
        updatedPartner: this.currentTab === 2 ? payload : payload.updatedPartner,
      }
      const payloadPartnership = {
        companyId: this.currentCompanyId,
        partnerId: this.currentPartnerId,
        updatedPartnership: this.currentTab === 2 ? this.updatedPartnership : payload.updatedPartnership,
      }
      this.showModal = false
      await Promise.all([this.updateSelectedPartner(payloadPartner), this.updateSelectedPartnership(payloadPartnership)])
      await this.forceRerender()
      this.isDataSaved = false
      this.editCard = false
    },
  },
}
</script>

<style scoped></style>
